<template>
    <div>

      <h4 class="mb-4">Ученики на контроле</h4>

      <div class="widget">
        <div class="filter__container">
          <div class="row mb-3 justify-content-between">
            <div class="input-group col-4">

                <input v-model="searchWord"
                       @keyup.enter="loadStudents()"
                       placeholder="Поиск..."
                       type="search"
                       class="form-control"
                       id="search-input">
                <span class="input-group-append">
                  <button @click.prevent="loadStudents()"
                          type="button"
                          class="btn btn-default"
                          style="border-top-right-radius: 0.5rem; border-bottom-right-radius: 0.5rem;">
                    Искать
                  </button>
                </span>
                <div class="spinner" :style="isProcessing ? { visibility: 'initial' } : { visibility: 'hidden' }"></div>

            </div>
          </div>
          <div class="input-group col-4">

          </div>
        </div>
        <table class="table table-hover" style="background: #ff000017;">
          <thead class="table_head">
          <tr>
            <th>ФИО ученика</th>
            <th>Телефон</th>
            <th>Телефон 2</th>
            <th>Преподаватель</th>
            <th>Дата</th>
            <th>Менеджер</th>
            <th>Действия</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="student in students" :key="student.id">
              <td>{{ student.full_name }}</td>
              <td>{{ student.parent_phone }}</td>
              <td>{{ student.parent_phone2 }}</td>
              <td>{{ student.teacher ? student.teacher.full_name : '' }}</td>
              <td>{{ student.debtor_at }}</td>
              <td>{{ student.debtorBy ? student.debtorBy.name : '' }}</td>
              <td class="actions_container">
                <router-link class="leads_table_action_link btn mr-2 btn-primary" title="Перейти на страницу ученика"
                             :to="{name: 'StudentDetail', params: {id: student.id}}">
                  <span class="glyphicon glyphicon-edit"/>
                </router-link>
                <a class="btn mr-2 btn-success" @click.prevent="setAsReleased(student.id)" title="Пометить как оплативший">
                  <span class="fa fa-dollar"></span>
                </a>
                <a class="btn mr-2 btn-danger" @click.prevent="unsetDebtor(student.id)" title="Снять с контроля">
                  <span class="fa fa-trash-o delete"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      <h4 class="mb-4">Оплатившие ученики</h4>


      <div class="widget">
        <table class="table table-hover" style="background: #4bff0017;">
          <thead class="table_head">
          <tr>
            <th>ФИО ученика</th>
            <th>Телефон</th>
            <th>Телефон 2</th>
            <th>Преподаватель</th>
            <th>Дата</th>
            <th>Менеджер</th>
            <th>Действия</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="student in releasedStudents" :key="student.id">
            <td>{{ student.full_name }}</td>
            <td>{{ student.parent_phone }}</td>
            <td>{{ student.parent_phone2 }}</td>
            <td>{{ student.teacher ? student.teacher.full_name : '' }}</td>
            <td>{{ student.debtor_released_at }}</td>
            <td>{{ student.debtorBy ? student.debtorBy.name : '' }}</td>
            <td class="actions_container">
              <router-link class="leads_table_action_link btn mr-2 btn-primary" title="Перейти на страницу ученика"
                           :to="{name: 'StudentDetail', params: {id: student.id}}">
                <span class="glyphicon glyphicon-edit"/>
              </router-link>
              <a class="btn mr-2 btn-danger" @click.prevent="unsetReleased(student.id)" title="Убрать из оплативших">
                <span class="fa fa-trash-o delete"></span>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>
</template>

<script>

    export default {
        name: 'Debtor',
        data() {
            return {
                searchWord: '',
                students: [],
                releasedStudents: [],
                isProcessing: false
            }
        },
        computed: {

        },

        methods: {

          async setAsReleased(id) {
            if (confirm('Пометить ученика как "Оплативший" и снять с контроля?')) {
              this.isProcessing = true;
              await this.$axios.post(`/api/set-student-as-released/${id}`);
              await this.loadStudents()
            }
          },
          async unsetDebtor(id) {
            if (confirm('Снять ученика с контроля?')) {
              this.isProcessing = true;
              await this.$axios.post(`/api/unset-student-as-debtor/${id}`);
              await this.loadStudents()
            }
          },
          async unsetReleased(id) {
            if (confirm('Убрать ученика из оплативших?')) {
              this.isProcessing = true;
              await this.$axios.post(`/api/unset-student-as-released/${id}`);
              await this.loadStudents()
            }
          },

          async loadStudents() {
            this.isProcessing = true;
            let response = await this.$axios.get(`/api/students`, {
                params: {
                  search: this.searchWord ? this.searchWord : null,
                  debtor: 1,
                }
            });
            this.students = response.data.items;

            response = await this.$axios.get(`/api/students`, {
              params: {
                search: this.searchWord ? this.searchWord : null,
                debtor_released: 1,
              }
            });
            this.releasedStudents = response.data.items;

            this.isProcessing = false;
          },

        },
        async created() {
          await this.loadStudents();
        }
    }
</script>

<style lang="scss" scoped>
  .btn .glyphicon {
    top: 0 !important;
  }
  .button_add {
    display: inline-block;
    vertical-align: middle;
    border: none;
    font-weight: 400;
    background: #3E86CA;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0 20px;
    font-size: 14px;
    height: 100%;
    transition: .1s;

    &:hover {
      background: #2e6ca7;
      text-decoration: none;
    }
  }


  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  .spinner {
    position: relative;
    margin-right: 30px;
    margin-left: 30px;
    visibility: hidden;
    /*top: -3px;*/
  }

  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #000;
    animation: spinner .6s linear infinite;
  }
</style>